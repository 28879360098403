
@mixin animation($animation) {
  -webkit-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

@mixin box-shadow($shadow, $inset: false) {
  @if $inset {
    -webkit-box-shadow: $inset, $shadow;
    -moz-box-shadow: $inset, $shadow;
    box-shadow: $inset, $shadow;
  } @else {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
  }
}

@mixin display-flex()  {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($value) {
  -webkit-box-flex: $value;
  -moz-box-flex: $value;
  -webkit-flex: $value;
  -ms-flex: $value;
  flex: $value;
}

@mixin placeholder($placeholder-color) {
  // Firefox
  &::-moz-placeholder {
    color: $placeholder-color;
    opacity: 1;
  }

  // Internet Explorer 10+
  &:-ms-input-placeholder {
    color: $placeholder-color;
  }

  // Safari and Chrome
  &::-webkit-input-placeholder  {
    color: $placeholder-color;
  }
}