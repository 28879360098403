
// Normalize.css
.rw-btn,
.rw-input-reset {
  color: inherit;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
  background: none;
  background-image: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}

.rw-btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}

select.rw-input {
  text-transform: none;
}

html input[type="button"].rw-input {
  -webkit-appearance: button;
  cursor: pointer;
}

textarea.rw-input {
  overflow: auto;
  resize: vertical;
}


button[disabled].rw-input,
fieldset[disabled] .rw-input,
html input[disabled].rw-input {
  cursor: not-allowed;
}

button.rw-input::-moz-focus-inner,
input.rw-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

/* -------------- */
