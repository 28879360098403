/* for debugging */
// *:focus {
//   outline: 1px red solid !important;
// }

@keyframes react-widgets-autofill-start {
  from {
    /**/
  }
  to {
    /**/
  }
}

@keyframes react-widgets-autofill-cancel {
  from {
    /**/
  }
  to {
    /**/
  }
}

.rw-sr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.rw-widget {
  @include border-box();

  border: none;
  color: $text-color;
  font-size: $font-size;
  font-family: $font-family;
  outline: none;
  position: relative;

  // ensure that the "chrome" is the same width as the actual container
  & > .rw-widget-container {
    width: 100%;
    margin: 0;
  }
}

.rw-widget-container {
  background-color: $input-bg;
  border: $input-border $input-border-width solid;
  border-radius: $input-border-radius;

  &.rw-state-focus,
  .rw-state-focus > & {
    &,
    &:hover {
      background-color: $input-bg-focus;
      border-color: $input-border-focus;
      box-shadow: $input-focus-box-shadow;
    }
  }

  &.rw-state-readonly,
  .rw-state-readonly > & {
    cursor: not-allowed;
  }

  &.rw-state-disabled,
  .rw-state-disabled > &,
  fieldset[disabled] & {
    &,
    &:hover,
    &:active {
      box-shadow: none;
      @include state-disabled();
    }
  }
}

.rw-widget-picker {
  position: relative;
  overflow: hidden;
  border-collapse: separate;
  display: inline-table;
  height: $input-height;

  & > * {
    position: relative;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    display: table-cell;
  }

  & > .rw-select {
    width: 1%;
    white-space: nowrap;
  }

  .rw-open > & {
    @include border-bottom-radius(0);
  }

  .rw-open-up > & {
    @include border-top-radius(0);
  }

  fieldset[disabled] &,
  .rw-state-disabled > & {
    background-color: $input-bg-disabled;
  }
}

.rw-select {
  cursor: pointer;

  & > * {
    width: 1.9em;
  }

  .rw-state-readonly &,
  .rw-state-disabled & {
    cursor: not-allowed;
  }
}

.rw-select-bordered {
  @include make-btn-state($select-btn-bg-hover);

  border: none;
  border-left: $input-border 1px solid;

  .rw-rtl & {
    border-right: $input-border 1px solid;
    border-left: none;
  }
}

.rw-rtl {
  direction: rtl;
}

.rw-input-reset {
  @include placeholder();
  outline: 0;
}

.rw-input {
  @extend .rw-input-reset;

  color: $input-color;
  padding: 0 $input-padding-horizontal;
  background-color: $input-bg;

  &[type='text']::-ms-clear {
    display: none;
  }

  &[disabled],
  fieldset[disabled] & {
    @include state-disabled();
    opacity: 1;
    background-color: $input-bg-disabled;
    border-color: $input-border-disabled;
  }

  &[readonly] {
    cursor: not-allowed;
  }
}

.rw-i.rw-loading {
  display: block;
  background: $img-busy no-repeat center;
  min-width: 16px;
  width: 1.9em;
  height: 16px;

  &:before {
    content: '';
  }
}

.rw-placeholder {
  color: $input-color-placeholder;
}

// https://github.com/klarna/ui/blob/master/Field/styles.scss
.rw-detect-autofill {
  &:-webkit-autofill {
    animation-name: react-widgets-autofill-start;

    // Make the backgound color become yellow _really slowly_
    transition: background-color 50000s ease-in-out 0s;
  }

  &:not(:-webkit-autofill) {
    animation-name: react-widgets-autofill-cancel;
  }
}

.rw-webkit-autofill .rw-widget-container,
.rw-input:-webkit-autofill {
  background-color: $input-bg-color-autofill !important;
  background-image: none !important;
  color: $input-color-autofill !important;
}

//input inset
.rw-widget-input {
  box-shadow: $input-box-shadow;
}

.rw-widget-input.rw-state-focus {
  box-shadow: #{$input-focus-box-shadow}, #{$input-box-shadow};
}
