// Header Mega Menu

.header-megamenu {
  &.nav {
    & > li > .nav-link {
      color: $gray-600;
      padding-left: ($nav-link-padding-x / 1.5);
      padding-right: ($nav-link-padding-x / 1.5);

      .badge-pill {
        padding: 5px 7px;
      }

      &:hover {
        color: $gray-800;
      }

      svg {
        margin-top: 1px;
      }
    }
  }
}