.smartb-card {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);
    transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
    padding: 14px 80px 18px 36px;
    cursor: pointer;
}

.smartb-card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}

.smartb-card h3 {
    font-weight: 600;
}

.smartb-card img {
    position: absolute;
    top: 20px;
    right: 15px;
    max-height: 120px;
}

.smartb-card {
    background-image: url(https://ionicframework.com/img/getting-started/ionic-native-card.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 80px;
}

/*.header__pane {*/
/*    visibility: hidden !important;*/
/*}*/

/*.header-dots {*/
/*    visibility: hidden !important;*/
/*}*/

.app-footer__inner {
    /*margin: 0 !important;*/
}